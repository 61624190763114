import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import productData from "../assets/data/products.json";
import Footer from "../components/Footer";
import cloudImage from "../assets/images/cloudimage1.png";
import redcap from "../assets/images/plant/redcap.png"; // Import your loading image here

const Products = () => {
    // Initially select "All" to display all products
    const [selectedCategory, setSelectedCategory] = useState("All");
    const [filteredProducts, setFilteredProducts] = useState(productData); // Show all products by default
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [mainImage, setMainImage] = useState(null); // For storing main product image

    const openModal = (product) => {
        setSelectedProduct(product);
        setMainImage(product.image); // Set the main image to the default product image
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setTimeout(() => {
            setSelectedProduct(null);
            setMainImage(null);
        }, 300);
    };

    const handleCategoryChange = (category) => {
        setIsLoading(true);
        setTimeout(() => {
            setSelectedCategory(category);
            setIsLoading(false);
        }, 500);
    };

    // Filtering logic
    useEffect(() => {
        if (selectedCategory === "All") {
            setFilteredProducts(productData); // Show all products when "All" is selected
        } else {
            const filtered = productData.filter(
                (product) => product.category === selectedCategory
            );
            setFilteredProducts(filtered);
        }
    }, [selectedCategory]);

    const handleGalleryClick = (image) => {
        setMainImage(image); // Update the main image when a gallery image is clicked
    };

    return (
        <div>
            <Navbar />
            <style>
                {`
                    @keyframes rotate {
                        from {
                            transform: rotate(0deg);
                        }
                        to {
                            transform: rotate(360deg);
                        }
                    }

                    .rotate {
                        animation: rotate 1s linear infinite;
                    }

                    /* Black background overlay when modal is open */
                    .modal-overlay {
                        position: fixed;
                        top: 0;
                        left: 0;
                        width: 100vw;
                        height: 100vh;
                        background-color: rgba(0, 0, 0, 0.75); /* Black with 75% opacity */
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 40;
                    }

                    /* Modal content */
                    .modal-content {
                        background-color: white;
                        padding: 20px;
                        border-radius: 10px;
                        max-width: 90%;
                        width: 100%;
                        max-height: 80vh;
                        overflow-y: auto;
                        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
                        z-index: 50;
                    }

                    /* Close button */
                    .close-button {
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        background-color: #ff5f5f;
                        color: white;
                        padding: 10px;
                        border-radius: 50%;
                        cursor: pointer;
                    }

                    /* For making modal responsive */
                    @media (min-width: 768px) {
                        .modal-content {
                            width: 70%;
                        }
                    }

                    @media (min-width: 1024px) {
                        .modal-content {
                            width: 50%;
                        }
                    }
                `}
            </style>

            {isLoading && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
                    <img
                        src={redcap} // Use your loading image here
                        alt="Loading..."
                        className="w-32 h-32 rotate" // Add the rotate class here
                    />
                </div>
            )}

            <div
                className={`container px-4 py-8 mx-auto mt-24 ${
                    isLoading ? "blur-sm" : ""
                }`}
            >
                <div className="mb-16 text-4xl font-bold text-center">
                    Our Products
                </div>

                {/* Category Mini-Carts */}
                <div className="grid grid-cols-2 gap-8 mb-12 sm:grid-cols-3 md:grid-cols-4">
                    {["All", "CSD", "Juice", "Water"].map((category) => (
                        <div
                            key={category}
                            className={`p-4 text-center bg-gray-200 rounded-lg shadow-lg cursor-pointer border ${
                                selectedCategory === category
                                    ? "border-blue-800"
                                    : "border-gray-300"
                            }`}
                            onClick={() => handleCategoryChange(category)}
                        >
                            <h2 className="text-xl font-bold">{category}</h2>
                        </div>
                    ))}
                </div>

                {/* Products Grid */}
                {!isLoading && (
                    <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
                        {filteredProducts.map((product) => (
                            <div
                                key={product.id}
                                className="p-4 transition-transform duration-300 ease-in-out transform bg-gray-200 bg-center bg-cover border border-blue-600 rounded-lg shadow-md cursor-pointer hover:scale-105 hover:shadow-lg hover:border-blue-900"
                                style={{
                                    backgroundImage: `url(${cloudImage})`,
                                    backgroundSize: "80%",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                }}
                            >
                                {/* Image Container */}
                                <div className="flex items-center justify-center w-full h-80">
                                    <img
                                        src={product.image}
                                        alt={product.name}
                                        className="object-contain w-auto h-full rounded-t-lg"
                                    />
                                </div>

                                {/* Product Name */}
                                <h2 className="mt-4 text-2xl font-semibold text-center">
                                    {product.name}
                                </h2>

                                {/* Button to View More Details */}
                                <div className="flex justify-center mt-4">
                                    <button
                                        onClick={() => openModal(product)}
                                        className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mb-2"
                                    >
                                        Explore More Product
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {/* Modal for Product Details */}
            {selectedProduct && (
                <div
                    className={`modal-overlay ${
                        isModalOpen ? "block" : "hidden"
                    }`}
                >
                    <div className="relative modal-content">
                        {/* Close Button */}
                        <button onClick={closeModal} className="close-button">
                            &times;
                        </button>

                        {/* Image Section (Centered on Small Devices) */}
                        <div className="flex items-center justify-center flex-shrink-0 w-full mb-4 md:w-1/3 md:mb-0">
                            <img
                                src={mainImage}
                                alt={selectedProduct.name}
                                className="object-contain w-64 h-64 rounded-lg md:w-80 md:h-80"
                            />
                        </div>

                        {/* Details Section */}
                        <div className="flex-grow mt-6 md:ml-6">
                            {/* Product Gallery */}
                            <div className="mt-4">
                                <h3 className="font-medium">
                                    Product Gallery :
                                </h3>
                                <div className="grid grid-cols-5 gap-4 mt-2">
                                    {selectedProduct?.gallery?.map(
                                        (image, index) => (
                                            <div>
                                                <img
                                                    key={index}
                                                    src={image.image}
                                                    alt={`Variation ${
                                                        index + 1
                                                    }`}
                                                    onClick={() =>
                                                        handleGalleryClick(
                                                            image.image
                                                        )
                                                    } // Handle gallery image click
                                                    className="object-cover h-32 border-2 border-red-500 rounded-lg cursor-pointer hover:opacity-75"
                                                />
                                                <span>{image.name}</span>
                                            </div>
                                        )
                                    ) || (
                                        <p>
                                            No images available in the gallery.
                                        </p>
                                    )}
                                </div>
                            </div>

                            <h2 className="mt-4 mb-4 text-2xl font-bold">
                                {selectedProduct.name}
                            </h2>
                            <p className="mb-4 text-gray-700">
                                {selectedProduct.description}
                            </p>
                            <div>
                                <span className="font-medium">
                                    {" "}
                                    Product Variations :
                                </span>
                                <ul className="pl-5 list-disc">
                                    {selectedProduct?.variations?.map(
                                        (variation, index) => (
                                            <li key={index}>{variation}</li>
                                        )
                                    ) || <p>No variations available.</p>}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <Footer />
        </div>
    );
};

export default Products;
