import React from "react";
import Navbar from "../components/Navbar";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import productData from "../assets/data/products.json"; // Ensure this path is correct
import Footer from "../components/Footer";
import SliderComponent from "../components/Slider";

const Home = () => {
    return (
        <div>
            <Navbar />
            <SliderComponent />
            <section className="py-16 bg-white">
                <div className="max-w-6xl px-6 mx-auto text-center">
                    <h2 className="mb-8 text-4xl font-bold text-gray-800">
                        Our Products
                    </h2>

                    {/* Responsive bottle display with uniform height */}
                    <div className="px-4 sm:px-6 lg:px-8">
                        <div className="flex flex-wrap items-center justify-center gap-4">
                            {productData.slice(0, 9).map((product) => (
                                <div
                                    key={product.id}
                                    className="flex flex-col items-center w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 xl:w-1/6"
                                >
                                    {/* Set a fixed height for uniformity */}
                                    <div className="h-[300px] sm:h-[350px] md:h-[400px] lg:h-[450px] rounded-lg overflow-hidden flex items-center justify-center">
                                        <img
                                            src={product.image}
                                            alt={product.name}
                                            className="object-cover w-full h-full transition duration-300 ease-in-out transform hover:scale-105"
                                        />
                                    </div>
                                    {/* Add a fixed height to the text container */}
                                    <h3 className="w-full mt-4 text-lg font-semibold text-center text-gray-700 truncate">
                                        {product.name}
                                    </h3>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-16 bg-gray-100">
                <div className="max-w-4xl px-6 mx-auto text-center">
                    <h2 className="mb-8 text-4xl font-bold text-gray-800">
                        Welcome to YesUp
                    </h2>
                    <p className="mb-6 text-lg leading-relaxed text-gray-600">
                        Welcome to YesUp, where innovation meets excellence! We
                        are thrilled to have you here and excited to showcase
                        our cutting-edge solutions and services tailored to meet
                        your needs. At YesUp, our mission is to provide you with
                        unparalleled experiences through our diverse range of
                        products designed to make a positive impact on your
                        daily life.
                    </p>
                    <p className="mb-6 text-lg leading-relaxed text-gray-600">
                        Explore our platform to discover a wealth of features
                        that enhance productivity, streamline operations, and
                        bring convenience right to your fingertips. From
                        intuitive interfaces to powerful functionalities, we’ve
                        crafted every aspect of YesUp with you in mind.
                    </p>
                    <p className="mb-6 text-lg leading-relaxed text-gray-600">
                        Our dedicated team is always here to support you and
                        ensure you make the most out of our offerings. We
                        believe in continuous improvement and innovation,
                        striving to bring you the latest advancements and best
                        practices in the industry.
                    </p>
                    <p className="mb-6 text-lg leading-relaxed text-gray-600">
                        Whether you're a new user or a long-time partner, we
                        invite you to explore our resources, engage with our
                        community, and take full advantage of what YesUp has to
                        offer. Stay tuned for updates, as we are constantly
                        working to bring you new and exciting features.
                    </p>
                    <p className="mb-6 text-lg leading-relaxed text-gray-600">
                        Thank you for choosing YesUp. We are excited to embark
                        on this journey with you and look forward to supporting
                        your success every step of the way. Together, let's
                        achieve great things and make a difference!
                    </p>
                </div>
            </section>

            <section className="py-20 text-gray-900 bg-gradient-to-br from-green-400 via-lime-500 to-yellow-300">
                <div className="max-w-4xl px-8 mx-auto text-center">
                    <h2 className="mb-6 text-5xl font-bold leading-tight tracking-wide text-teal-900">
                        STAY UPDATED!
                    </h2>
                    <p className="mb-12 text-lg text-teal-800">
                        Subscribe to our newsletter to get the latest beverage
                        recipes, special offers, and fresh content delivered to
                        your inbox.
                    </p>

                    {/* Form Section */}
                    <form className="relative flex items-center justify-center max-w-xl mx-auto">
                        <input
                            type="email"
                            placeholder="Enter your email address"
                            className="w-full px-6 py-4 text-gray-800 transition-all duration-300 ease-in-out bg-white border-0 rounded-full shadow-lg focus:outline-none focus:ring-4 focus:ring-green-500"
                            required
                        />
                        <button
                            type="submit"
                            className="absolute right-0 px-8 py-4 text-lg font-semibold text-white transition-transform duration-300 ease-in-out transform bg-orange-500 rounded-full shadow-lg hover:bg-orange-600 focus:outline-none focus:ring-4 focus:ring-orange-400 hover:scale-105"
                        >
                            Subscribe
                        </button>
                    </form>

                    {/* Subtle message */}
                    <p className="mt-6 text-sm italic text-teal-700">
                        We promise no spam, only the freshest updates!
                    </p>

                    {/* Decorative Elements */}
                    <div className="flex justify-center mt-12 space-x-4 animate-bounce-slow">
                        <div className="w-4 h-4 bg-orange-500 rounded-full shadow-lg"></div>
                        <div className="w-4 h-4 bg-green-500 rounded-full shadow-lg"></div>
                        <div className="w-4 h-4 bg-yellow-400 rounded-full shadow-lg"></div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
};

export default Home;
