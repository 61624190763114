import React, { useState, useEffect } from "react";
import plant3 from "../assets/images/plant/plant3.jpg";
import plant5 from "../assets/images/plant/plant5.jpg";
import plant7 from "../assets/images/plant/plant7.jpg";
import plant8 from "../assets/images/plant/plant8.jpg";

const Slider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const slides = [plant3, plant5, plant7, plant8]; // Images only

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) =>
                prevSlide === slides.length - 1 ? 0 : prevSlide + 1
            );
        }, 4000); // Slide changes every 5 seconds

        return () => clearInterval(interval);
    }, [slides.length]);

    const handlePrev = () => {
        setCurrentSlide((prevSlide) =>
            prevSlide === 0 ? slides.length - 1 : prevSlide - 1
        );
    };

    const handleNext = () => {
        setCurrentSlide((prevSlide) =>
            prevSlide === slides.length - 1 ? 0 : prevSlide + 1
        );
    };

    return (
        <div className="relative w-full mx-auto max-w-8xl">  {/* Increased top margin to mt-40 */}
        {/* Slider Container */}
        <div className="relative flex items-center justify-center w-full h-auto mt-[81px] overflow-hidden">
            {/* Image Slide */}
            <img
                src={slides[currentSlide]}
                className="object-cover w-full h-96 md:h-[450px] lg:h-[450px]"  // Full width, responsive height
                alt={`Slide ${currentSlide + 1}`}
            />
        </div>
    
        {/* Previous Button */}
        <button
            onClick={handlePrev}
            className="absolute p-2 text-white transform -translate-y-1/2 bg-gray-800 bg-opacity-50 rounded-full top-1/2 left-2"
        >
            <span className="sr-only">Previous</span>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="w-6 h-6"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19l-7-7 7-7"
                />
            </svg>
        </button>
    
        {/* Next Button */}
        <button
            onClick={handleNext}
            className="absolute p-2 text-white transform -translate-y-1/2 bg-gray-800 bg-opacity-50 rounded-full top-1/2 right-2"
        >
            <span className="sr-only">Next</span>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="w-6 h-6"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5l7 7-7 7"
                />
            </svg>
        </button>
    </div>
    
    );
};

export default Slider;
