import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import plant4 from "../assets/images/plant/plant4.jpg"; // Adjust the path according to your project structure

const AboutUs = () => {
    return (
        <div className="min-h-screen mt-10 bg-gray-50">
            <Navbar />
            <div className="py-16">
                <div className="px-6 mx-auto max-w-7xl lg:px-8">
                    {/* Hero Section */}
                    <section className="text-center">
                        <h1 className="text-4xl font-bold text-center mt-7">
                            About Us
                        </h1>
                        <p className="mt-4 text-lg text-gray-700">
                            Welcome to Yes-Up, a leading name in refreshing
                            beverages that inspire moments of joy. Our mission
                            is to deliver high-quality drinks that refresh and
                            energize, with a focus on innovation and flavor.
                        </p>
                    </section>

                    {/* Our Commitment Section */}
                    <section className="flex flex-col items-center mt-12 md:flex-row">
                        {/* Plant Image on Left Side */}
                        <div className="w-full p-4 md:w-1/2 lg:w-1/3">
                            <img
                                src={plant4} // Replace 'plant4' with your plant image URL
                                alt="Our Plant"
                                className="object-cover w-full h-64 rounded-lg shadow-lg" // Adjust height as needed
                            />
                        </div>

                        {/* Content on Right Side */}
                        <div className="w-full p-4 md:w-1/2 lg:w-2/3">
                            <h2 className="text-3xl font-semibold text-gray-900">
                                Our State-of-the-Art Plant
                            </h2>

                            <p className="mt-4 text-lg text-gray-700">
                                At{" "}
                                <span className="font-semibold text-green-600">
                                    Yes-Up Beverages
                                </span>
                                , our plant is the cornerstone of our commitment
                                to delivering high-quality beverages. Equipped
                                with cutting-edge technology, our facility
                                ensures that every bottle meets the highest
                                standards of production, hygiene, and safety.
                            </p>

                            <ul className="mt-6 text-lg text-gray-700 list-disc list-inside">
                                <li>
                                    Advanced manufacturing systems for
                                    consistent product quality.
                                </li>
                                <li>
                                    High-capacity production lines capable of
                                    producing thousands of bottles daily.
                                </li>
                                <li>
                                    Environmentally conscious operations
                                    focusing on sustainability and minimal
                                    waste.
                                </li>
                                <li>
                                    Rigorous quality control measures at every
                                    stage of production.
                                </li>
                            </ul>

                            <p className="mt-6 text-lg text-gray-700">
                                Our dedication to excellence is reflected in
                                every aspect of our plant, from its modern
                                infrastructure to the expert team that ensures
                                the smooth and efficient running of daily
                                operations.
                            </p>
                        </div>
                    </section>

                    {/* Mission, Vision, and Values Section */}
                    <section className="mt-12">
                        <div className="relative grid gap-8 mt-12 md:grid-cols-1 lg:grid-cols-3">
                            <div className="relative p-8 text-center transition duration-300 ease-in-out bg-white border border-yellow-600 rounded-lg shadow-lg hover:border-blue-500 hover:shadow-xl">
                                <div className="absolute inset-0 object-cover w-full h-full bg-orange-500 rounded-lg opacity-50"></div>
                                <div className="relative z-10 p-6 bg-white rounded-lg bg-opacity-80">
                                    <h3 className="text-2xl font-semibold text-gray-900">
                                        Our Mission
                                    </h3>
                                    <p className="mt-4 text-gray-600">
                                        At Yes-Up, our mission is to create
                                        beverages that are refreshing and
                                        energizing, crafted with passion and
                                        precision. We aim to be the go-to brand
                                        for consumers who seek a flavorful,
                                        refreshing experience, bringing joy with
                                        every sip.
                                    </p>
                                </div>
                            </div>
                            <div className="relative p-8 text-center transition duration-300 ease-in-out bg-white border border-yellow-600 rounded-lg shadow-lg hover:border-blue-500 hover:shadow-xl">
                                <div className="absolute inset-0 object-cover w-full h-full bg-gray-400 rounded-lg opacity-50"></div>
                                <div className="relative z-10 p-6 bg-white rounded-lg bg-opacity-80">
                                    <h3 className="text-2xl font-semibold text-gray-900">
                                        Our Vision
                                    </h3>
                                    <p className="mt-4 text-gray-600">
                                        Our vision is to become a globally
                                        recognized brand for innovative and
                                        refreshing beverages, known for quality
                                        and excellence. We strive to create
                                        drinks that not only quench thirst but
                                        also inspire happiness and positivity in
                                        every consumer.
                                    </p>
                                </div>
                            </div>
                            <div className="relative p-8 text-center transition duration-300 ease-in-out bg-white border border-yellow-600 rounded-lg shadow-lg hover:border-blue-500 hover:shadow-xl">
                                <div className="absolute inset-0 object-cover w-full h-full bg-green-500 rounded-lg opacity-50"></div>
                                <div className="relative z-10 p-6 bg-white rounded-lg bg-opacity-80">
                                    <h3 className="text-2xl font-semibold text-gray-900">
                                        Our Values
                                    </h3>
                                    <p className="mt-4 text-gray-600">
                                        At the heart of Yes-Up are values of
                                        quality, innovation, and sustainability.
                                        We believe in creating products that are
                                        not only refreshing but also responsibly
                                        sourced and produced. We are committed
                                        to delivering excellence and leaving a
                                        positive impact on both our consumers
                                        and the environment.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default AboutUs;
