import React from "react";
import {
    FaFacebookF,
    FaTwitter,
    FaInstagram,
    FaLinkedinIn,
} from "react-icons/fa";
import "tailwindcss/tailwind.css";
import { Link } from "react-router-dom"; // Import Link from react-router-dom for navigation
import { FaLocationDot } from "react-icons/fa6";

const Footer = () => {
    return (
        <footer className="py-8 text-gray-100 bg-gradient-to-r from-purple-700 via-pink-600 to-red-500">
            <div className="container px-4 mx-auto">
                <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
                    {/* About Section */}
                    <div>
                        <h3 className="mb-4 text-xl font-semibold text-white">
                            About Us
                        </h3>
                        <p className="text-gray-300">
                            Yes-Up Beverages offers a wide range of high-quality
                            beverages to keep you refreshed and energized.
                            Discover our story and learn more about our
                            commitment to excellence.
                        </p>
                    </div>

                    {/* Contact Section */}
                    <div>
                        <h3 className="mb-4 text-xl font-semibold text-white">
                            Contact Us
                        </h3>
                        <ul className="text-gray-300">
                            <li className="mb-2">Silvostar Beverages,</li>
                            <li className="mb-2">Nr. Telephone Exchange,</li>
                            <li className="mb-2">Dhangdhra, Hadvad,</li>
                            <li className="mb-2">Dist. Morbi,</li>
                            <li className="mb-2">Gujarat - 363330</li>
                        </ul>
                    </div>

                    {/* Follow Us and Navigation Links Section */}
                    <div className="flex justify-between md:items-start">
                        <div className="flex flex-col">
                            <h3 className="mb-4 text-xl font-semibold text-white">
                                Links
                            </h3>
                            <Link
                                to="/"
                                className="mb-2 text-gray-300 hover:text-white"
                            >
                                Home
                            </Link>
                            <Link
                                to="/about-us"
                                className="mb-2 text-gray-300 hover:text-white"
                            >
                                About
                            </Link>
                            <Link
                                to="/products"
                                className="mb-2 text-gray-300 hover:text-white"
                            >
                                Products
                            </Link>
                            <Link
                                to="/contact-us"
                                className="text-gray-300 hover:text-white"
                            >
                                Contact
                            </Link>
                        </div>

                        {/* Social Media Section */}
                        <div className="flex flex-col mr-16">
                            <h3 className="mb-4 text-xl font-semibold text-white">
                                Follow Us
                            </h3>
                            <div className="flex mb-4 space-x-4">
                                <a
                                    href="https://facebook.com/yesupbeverages"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaFacebookF
                                        className="text-gray-300 hover:text-white"
                                        size={24}
                                    />
                                </a>
                                <a
                                    href="https://twitter.com/yesupbeverages"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaTwitter
                                        className="text-gray-300 hover:text-white"
                                        size={24}
                                    />
                                </a>
                                <a
                                    href="https://www.instagram.com/yesup_coldring?igsh=aHI4cm1zdmZzN2tw"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaInstagram
                                        className="text-gray-300 hover:text-white"
                                        size={24}
                                    />
                                </a>
                                <a
                                    href="https://linkedin.com/company/yesupbeverages"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaLinkedinIn
                                        className="text-gray-300 hover:text-white"
                                        size={24}
                                    />
                                </a>
                                <a
                                    href="https://maps.app.goo.gl/HeaPJrE66buiq1V88"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaLocationDot
                                        className="text-gray-300 hover:text-white"
                                        size={24}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pt-4 mt-8 text-center border-t border-gray-600">
                    <p className="text-sm text-gray-300">
                        &copy; {new Date().getFullYear()} Yes-Up Beverages. All
                        rights reserved.
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
